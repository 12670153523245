<template>
     <div class="d-block d-lg-none">
    <div class="footer fixed-bottom">
    <nav  class="nav nav-fill bottom-nav">
  <router-link to="/user/index" class="nav-link bottom-link footer-image-aftersignup" aria-current="page" :class="{ 'tab-activated': isRouteActive('/user/index') }" ><font-awesome-icon :icon="['fas','home']" /><p class="bottom-title">Home</p></router-link>
  <router-link to="/user/previewcard" class="nav-link bottom-link footer-image-aftersignup" aria-current="page" :class="{ 'tab-activated': isRouteActive('/user/previewcard') }" ><font-awesome-icon :icon="['fas', 'clinic-medical']" /><p class="bottom-title">Clinics</p></router-link>
  <router-link to="/user/community-groups" class="nav-link bottom-link footer-image-aftersignup" aria-current="page" :class="{ 'tab-activated': isRouteActive('/user/community-groups') }" ><font-awesome-icon :icon="['fas', 'user-friends']" /><p class="bottom-title">Groups</p></router-link>              
  <!-- <router-link to="/user/publish" class="nav-link bottom-link" aria-current="page" ><font-awesome-icon :icon="['fas','stethoscope']" /><p class="bottom-title">Publish</p></router-link> -->
  <router-link to="/user/records" class="nav-link bottom-link footer-image-aftersignup" aria-current="page" :class="{ 'tab-activated': isRouteActive('/user/records') }" ><font-awesome-icon :icon="['fas', 'file-medical']" /><p class="bottom-title">Records</p></router-link>
  <!-- <router-link to="" class="nav-link bottom-link footer-image-aftersignup" aria-current="page" :class="{ 'tab-activated': isRouteActive('/user/request') }" ><font-awesome-icon :icon="['fas', 'list']" /><p class="bottom-title">Requests</p></router-link> -->
  <router-link to="/user/library" class="nav-link bottom-link footer-image-aftersignup" aria-current="page" :class="{ 'tab-activated': isRouteActive('/user/library') }" ><font-awesome-icon :icon="['fas','book-medical']" /><p class="bottom-title">Library</p></router-link>
  </nav>
  </div>
</div>
</template>
<script>
export default {
    props: ["role"],
    data() {
    return {

    }},
    created: function () {
  },
    methods: {
  currentPath(routePath) {
      this.$router.push(routePath);
    },
    isRouteActive(routePath) {
      return this.$route.path === routePath;
    },

    }
}
</script>
<style>
.footer-image-aftersignup{
  margin: 0px !important;
  color: #000 !important;
  cursor: pointer;
  padding: 7px 14px;
}
.tab-activated {
  background-color:#00979e;
  color: #fff !important;
  margin: 0px;
}
</style>